<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="500"
        >
            <v-card>
                <v-card-title :class="title.color" class="text-h5 white--text" >
                    {{ title.text }}
                </v-card-title>

                <v-card-text>
                    <h3>{{ messageText }}</h3>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :color="title.color"
                        outlined
                        text
                        @click="dialog = false"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "Message",
    props: [ 'messageText', 'type'],
    created() {
        this.msgType();
        this.dialog = true;
    },
    data(){
        return {
            title: {
                color: '',
                text: '',
            },
            dialog: false,
        };
    },
    methods: {
        msgType() {
            switch (this.type.toLowerCase()) {
                case 'error':
                    this.title.color = 'error';
                    this.title.text = 'ERROR'
                    break;
                case 'info':
                    this.title.color = 'info';
                    this.title.text = 'INFO'
                    break;
                case 'success':
                    this.title.color = 'success';
                    this.title.text = 'SUCCESS'
                    break;
                default:
                    this.title.color = 'warning';
                    this.title.text = 'OH OH'
                    break;
            }
        },
    },
}
</script>
